var mapLarge;
var mapCoordinate;
var mapTitle = 'Restaurant d\'Vijff Vlieghen';
var mapLatitude = 52.369381;
var mapLongitude = 4.888326;
var mapUrl = 'https://www.google.nl/maps/place/d\'Vijff+Vlieghen/@52.3693616,4.8877524,19z/data=!3m1!4b1!4m5!3m4!1s0x47c609c17f68acf1:0x5980418ad3ad18ec!8m2!3d52.3693616!4d4.8882996';

function getMapOptions() {
    return {
        zoom: 14,
        center: new google.maps.LatLng(mapLatitude, mapLongitude, 4),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false,
        draggable: false,
        styles: [{
            "featureType": "water",
            "stylers": [{"visibility": "on"}, {"color": "#b5cbe4"}]
        }, {"featureType": "landscape", "stylers": [{"color": "#efefef"}]}, {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [{"color": "#83a5b0"}]
        }, {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [{"color": "#bdcdd3"}]
        }, {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [{"color": "#ffffff"}]
        }, {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [{"color": "#e3eed3"}]
        }, {
            "featureType": "administrative",
            "stylers": [{"visibility": "on"}, {"lightness": 33}]
        }, {"featureType": "road"}, {
            "featureType": "poi.park",
            "elementType": "labels",
            "stylers": [{"visibility": "on"}, {"lightness": 20}]
        }, {}, {"featureType": "road", "stylers": [{"lightness": 20}]}]
    }
}

function handleClickMarker() {
    window.open(this.url, '_blank');
}

function initSmallFooterMap() {
    if (document.getElementById('map') != null) {
        var map = new google.maps.Map(document.getElementById('map'), getMapOptions());
        var marker = new google.maps.Marker({
            position: mapCoordinate,
            map: map,
            icon: {
                url: '/assets/dist/img/maps-marker.png',
                anchor: new google.maps.Point(22, 62)
            },
            title: mapTitle,
            url: mapUrl
        });
        google.maps.event.addListener(marker, 'click', handleClickMarker);
    }
}

function initLargeFooterMap() {
    if (document.getElementById('map-large') != null) {
        mapLarge = new google.maps.Map(document.getElementById('map-large'), $.extend(getMapOptions(), {scrollwheel: true, draggable: true}));
        var markerLarge = new google.maps.Marker({
            position: mapCoordinate,
            map: mapLarge,
            icon: {
                url: '/assets/dist/img/maps-marker.png',
                anchor: new google.maps.Point(22, 62)
            },
            title: mapTitle,
            url: mapUrl
        });
        google.maps.event.addListener(markerLarge, 'click', handleClickMarker);
    }
}

function initContactMap() {
    if (document.getElementById('contact-map') != null) {
        var mapContact = new google.maps.Map(document.getElementById('contact-map'), getMapOptions());
        var markerContactPage = new google.maps.Marker({
            position: mapCoordinate,
            map: mapContact,
            icon: {
                url: '/assets/dist/img/maps-marker.png',
                anchor: new google.maps.Point(22, 62)
            },
            title: mapTitle,
            url: mapUrl
        });
        google.maps.event.addListener(markerContactPage, 'click', handleClickMarker);
    }
}

function refocusMap() {
    if (document.getElementById('map-large') != null) {
        var fullMap = $('footer .map-opened');
        var isFullMapOpen = fullMap.hasClass('opened');

        mapLarge.panTo(mapCoordinate);
        if (!isFullMapOpen) {
            mapLarge.panBy(-300, null);
        }
    }
}

function initMap() {
    mapCoordinate = new google.maps.LatLng(mapLatitude, mapLongitude, 4);
    initSmallFooterMap();
    initLargeFooterMap();
    initContactMap();
    refocusMap();
}

$(function () {
    $('footer .toggle-map').on('click', function (e) {
        $('footer .map-opened').toggleClass('opened');
        refocusMap();
    });
});
